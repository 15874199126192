import axios from "axios";

export let headers = {
	"Tenant-Access-Token": "ZWE5YTgwZjUzY2Q3YTgyNDAzZjMxZjRmYjc5YjJlNjE=",
	"Tenant-Access-Key": "cvdPWcgu-A0dgA",
};

export const BASE_URL = "https://immersive-game-india.confirmu.com";

export const api = axios.create({
	baseURL: BASE_URL,
	headers,
});
