import React from "react";
import "./style.scss";
import logo from "../../assets/images/LOGO.png";
import game_logo from "../../assets/short-game-assets/game_logo.png";

const SplashScreen = () => {
	return (
		<>
			<div className="image__container">
				<img src={game_logo} alt="logo" className="logo__main" />
			</div>
		</>
	);
};

export default SplashScreen;
